<template>
  <div class="app-container">
    <div class="titletop">
      <div class="cname mt20">
        <span>客户名称：{{ cname }}</span>
      </div>
      <div class="session1">
        <div class="shopBox" ref="shopBox" @click="treeClick">
          <i>组织：</i>
          <input type="text" readonly name="" id="" :value="checkedName" />
          <span class="el-tree-node__expand-icon el-icon-caret-right expanded"></span>
        </div>
        <div class="treeBox" ref="treeBox" v-show="treeIsShow">
          <el-input placeholder="请输入组织名称搜索" v-model="searchOrg" @change="searchOrg" class="input-with-select">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
          <el-tree
            node-key="orgcode"
            :data="shopsTree"
            :props="defaultProps"
            ref="tree"
            :default-expanded-keys="[orgcode]"
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            highlight-current="true"
            @node-click="handleNodeClick"
          >
          </el-tree>
          <div class="dialogBtn pt20 mb20 borderTop">
            <button @click="getOrgcode" type="button" class="ml20 ag-model-btn blue fts mr5">确定</button>
            <button @click="hideAll" type="button" class="ag-model-btn gray fts">取消</button>
          </div>
        </div>
        <span class="ml20">角色信息：</span>
        <el-select v-model="roleIdList" multiple size="small" style="width: 300px" filterable @change="getUserList">
          <el-option v-for="(item, index) in roleList" :key="index" :label="item.label" :value="item.id"></el-option>
        </el-select>
        <!-- <div class="role-box" @click="openDialog">
        <div
          class="role-detail"
          id="roleContent"
          v-text="allRoleText()"
          placeholder="请选择"
        ></div>
        <span
          class="el-select__caret el-input__icon el-icon-arrow-down"
          :class="{'is-reverse':roleDialog}"
        ></span>
      </div> -->
        <el-input
          placeholder="请输入用户名/邮箱/手机号"
          v-model="keyword"
          @change="searchUserList"
          style="width: 300px"
          class="searchInput ml20"
        >
          <el-button class="searchbtn" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <div class="operation flex flex-between">
        <el-button
          v-if="vm.PowerData.nrcp_user_add !== 2"
          :disabled="vm.PowerData.nrcp_user_add === 1"
          type="primary"
          @click="addUser"
        >
          <!-- <i class="iconfont icon-icon-kehuguanli"></i> -->
          新增用户
        </el-button>
        <a :href="downloadUrl">
          <el-button><i class="fa fa-download fhd"></i> </el-button>
        </a>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData">
        <el-table-column label="用户" prop="name"></el-table-column>
        <el-table-column label="角色" prop="roleNameList" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.roleNameList.join('、') }}
          </template>
        </el-table-column>
        <el-table-column label="组织权限" prop="orgNameList" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.orgNameList.join('、') }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editUserAuth(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="removeUser(scope.row)">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="mt20 fr">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 30, 50]"
        :page-size="10"
        layout="total,sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- <el-dialog title="用户组织权限编辑" :visible.sync="userAuthEditDialog">
    <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="mt20">
      <el-form-item prop="roles" label="角色：">
        <div class="role-box" @click="openDialog">
          <div class="role-detail" id="roleContent" v-text="allRoleText()" placeholder="请选择">
          </div>
          <span class="el-select__caret el-input__icon el-icon-arrow-down" :class="{'is-reverse':roleDialog}"></span>
        </div>
      </el-form-item>
      <el-form-item label="组织权限：" :class="{'required':form.ugid === '','is-error':errorOrg}">
        <div class="session" ref="shopBox">
          <div class="shop-content" @click="showTree">
            <ul class="shop-name" />
            <li v-for="(item,index) in checkNodes" :key="index">{{item.name}}<i class="icon-delete"
                @click.stop="deleteNode(item)">×</i></li>
            </ul>
            <span class="el-select__caret el-input__icon el-icon-arrow-down" :class="{'is-reverse':treeIsShow}"></span>
            <div v-show="errorOrg" class="el-form-item__error">请选择</div>
          </div>
          <div class="treeBox mb20" v-show="treeIsShow" style="display: none;">
            <div class="tree-content">
              <el-input placeholder="请输入客户组织名称" v-model="filterText">
              </el-input>
              <el-tree node-key="orgcode" :data="shopsTree" :props="defaultProps" :default-expanded-keys="[orgcode]"
                ref="tree" :filter-node-method="filterNode" :expand-on-click-node="false" :highlight-current='true'
                show-checkbox>
              </el-tree>
            </div>
            <div class="dialog-btn borderTop">
              <button @click="getOrgcode" type="button" class="ml20 ag-model-btn blue fts mr5">确定</button>
              <button @click="hideAll" type="button" class="ag-model-btn gray fts">取消</button>
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <template slot="footer" class="dialog-footer">
      <el-button @click="userAuthEditDialog = false">取 消</el-button>
      <el-button type="primary" @click="submitForm"
        >确 定</el-button
      >
    </template>
  </el-dialog> -->
    <el-dialog title="角色管理" :visible.sync="roleDialog" width="531px" :before-close="handleClose">
      <div class="flex alignCenter">
        <div class="role-left">
          <div v-for="(item, index) in roleList" v-show="!item.addRight" :key="index">
            <div class="checktitle">新零售-{{ item.pname }}</div>
            <el-radio
              class="rediocontain"
              v-model="item.radioValue"
              v-for="(ele, key) in item.roleList"
              :key="key"
              :label="ele.rid"
              >{{ ele.rname }}
            </el-radio>
          </div>
        </div>
        <div class="transfer">
          <el-button @click="addRole" :disabled="addBtn">添加 ></el-button>
          <el-button @click="cancelSelect" :disabled="!selectList.length"> 取消</el-button>
        </div>
        <div class="role-right">
          <div v-for="(item, index) in roleList" v-show="item.addRight" :key="index">
            <div class="checktitle">新零售-{{ item.pname }}</div>
            <el-radio class="rediocontain" v-model="selectList[index]" :label="item.pid">
              <span v-text="selectName(item)"></span>
            </el-radio>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="roleDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitRole">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import treemixin from 'src/mixins/tree'
import { queryCustomerList, removeUser, getPlatRoles, getOrgShopTree } from 'src/api/legacy'
import { getQueryString } from 'src/utils/index.js'
import { vm } from 'src/utils/public'

var httpUrl = '/newretail'
var storeHttp = '/newretail'
var storeHttpV2 = '/newretail/v2'

export default {
  mixins: [treemixin],
  data() {
    return {
      cid: '',
      cname: '',
      orgcode: '',
      checkedName: '',
      roleInfo: '',
      keyword: '',
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      userInfo: {},
      userAuthEditDialog: false,
      roleDialog: false,
      roleList: [],
      selectList: [],
      roles: [],
      shopsTree: [],
      filterText: '',
      roleIdList: [],
      form: {
        roles: [],
      },
      rules: {
        roles: [
          {
            required: true,
            message: '请选择',
            trigger: 'click',
          },
        ],
      },
      total: 0,
      errorOrg: false,
      treeIsShow: false, //树显示
      checkNodes: [], //默认组织树名

      vm,
    }
  },
  computed: {
    downloadUrl() {
      return `${storeHttp}/api/customer/downloadUserList?cid=${this.cid}&org=${
        this.orgcode
      }&roles=${this.roleIdList.join()}&keyword=${this.keyword}&pageSize=${this.total}`
    },
    addBtn() {
      return this.roleList.every((item) => {
        return item.radioValue === '' || item.addRight
      })
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  mounted() {
    this.getRoleList()
    this.cname = localStorage.getItem('cname')
    this.cid = this.$route.query.cid
    this.orgShopTree().then(() => {
      this.$refs.tree.setCurrentKey(this.orgcode)
      this.overviewInit()
    })
  },
  methods: {
    searchUserList() {
      this.currentPage = 1
      this.getUserList()
    },
    //页面初始化
    overviewInit() {
      this.getUserList()
    },
    openDialog() {
      this.roleDialog = true
    },
    allRoleText() {
      return this.roles && this.roles.map((v) => (v.pname ? `${v.pname}： ${this.selectName(v)}` : '')).join('、')
    },
    editAllRoleText() {
      return this.roles && this.roles.map((v) => (v.pname ? `${v.pname}： ${this.selectName(v)}` : '')).join('、')
    },
    getUserList() {
      let data = {
        cid: this.cid,
        org: this.orgcode,
        roleIdSet: this.roleIdList,
        keyword: this.keyword,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
      }
      const loading = this.$loading({
        text: '加载中',
        target: document.querySelector('.el-table__body'),
      })

      queryCustomerList(data)
        .then((res) => {
          this.tableData = res.data.data
          this.total = res.data.totalSize
        })
        .finally(() => loading.close())
    },
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.getUserList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getUserList()
    },
    removeUser(row) {
      this.$confirm('是否确认将该账号从客户的用户列表中移除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning',
      }).then(() => {
        let data = {
          cid: this.cid,
          uid: row.id,
        }
        removeUser(data).then((res) => {
          if (res.status === 0) {
            this.$message.success('移除成功')
            this.getUserList()
          }
        })
      })
    },
    addUser() {
      this.$router.push({
        name: 'addUser',
        query: {
          cid: this.cid,
        },
      })
    },
    editUserAuth(row) {
      // this.userInfo = row
      // this.userAuthEditDialog = true
      // this.orgShopTree()
      row.cid = this.cid
      localStorage.setItem('userInfo', JSON.stringify(row))
      this.$router.push({ name: 'editUser' })
    },
    //查询各平台角色
    getRoleList() {
      getPlatRoles().then((res) => {
        if (res && res.status === 0) {
          if (res.status == 0) {
            for (const v of res.data) {
              for (const item of v.roleList) {
                this.roleList.push({
                  label: `${v.pname}:${item.rname}`,
                  id: item.rid,
                })
              }
            }
          }
        }
      })
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(() => {
          done()
        })
        .catch(() => {})
    },
    addRole() {
      //添加
      this.roleList.forEach((item) => {
        if (item.radioValue) {
          item.addRight = true
        }
      })
    },
    cancelSelect() {
      //取消
      this.roleList.forEach((item) => {
        if (this.selectList.includes(item.pid)) {
          item.addRight = false
          item.radioValue = ''
        }
      })
      this.selectList = []
    },
    selectName(item) {
      if (item.radioValue) {
        return item.roleList.find((v) => {
          return v.rid === item.radioValue
        }).rname
      }
    },
    submitRole() {
      this.roleDialog = false
      let list = [...this.roleList]
      this.roles = list.filter((v) => {
        return v.radioValue && v.addRight === true
      })
      this.getUserList()
    },
    hideAll() {
      //隐藏树组织
      this.treeIsShow = false
    },
    treeClick() {
      //显示树组织
      this.treeIsShow = true
    },
    filter(checkNodes) {
      const orgcodeArr = checkNodes.map((n) => n.orgcode)
      const index = orgcodeArr.findIndex((item) => item === '0')
      orgcodeArr.splice(index, 1)
      return checkNodes.filter((v) => !orgcodeArr.includes(v.parentCode))
    },
    orgShopTree() {
      //获取树文件数据
      let data = {
        cid: this.cid,
      }
      return getOrgShopTree(data).then((res) => {
        if (res.data && res.status === 0) {
          // res.data.disabled = false
          this.orgcode = res.data.orgcode
          this.shopsTree = [res.data]
          this.checkedName = res.data.name
          this.$refs.tree.setCurrentKey(this.orgcode)
        }
      })
    },
    validateOrg(val, text) {
      if (!this.orgcode.length) {
        this.errorOrg = true
        return false
      } else {
        this.errorOrg = false
        return true
      }
    },
    showTree() {
      this.treeClick()
    },
    deleteNode(node) {
      const index = this.checkNodes.findIndex((v) => {
        return node === v
      })
      this.checkNodes.splice(index, 1)
      this.$refs.tree.setCheckedNodes(this.checkNodes)
      this.validateOrg()
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        console.log(valid)
        if (valid) {
          this.updateUser()
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.navtitle {
  width: 100%;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #d1d1d1;
  font-size: 14px;
}
.session1 {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.treeBox {
  top: 40px !important;
  left: 45px !important;
}
.cname span {
  font-size: 16px;
}
.user-info {
  margin-left: 100px;
}
.user-info span:not(:first-child) {
  margin-left: 20px;
}
.role-box {
  width: 250px;
}
.role-box .el-icon-arrow-down {
  color: #c0c4cc;
  position: absolute;
  top: 0px;
  right: 6px;
}
.is-reverse {
  transform: rotate(180deg);
}
.role-detail {
  width: 200px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #dcdfe6;
  padding: 0 30px 0 15px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.el-tree {
  max-height: 268px;
  overflow-y: auto;
  padding: 0 !important;
}

.session1 .shop-content {
  /* margin-top: 5px; */
  position: relative;
  width: 468px;
}

.shop-content .shop-name {
  width: 468px;
  min-height: 36px;
  line-height: 36px;
  padding: 0px 24px 0px 10px;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}
.shop-name li {
  height: 22px;
  line-height: 22px;
  padding: 0 20px 0 8px;
  display: inline-block;
  background-color: #fff;
  margin-right: 8px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  position: relative;
}

li .icon-delete {
  position: absolute;
  right: 0;
  padding: 0 8px 0 10px;
}
.el-input__icon {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
